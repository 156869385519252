<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { navigateTo } from "#app";
import { AuthStrategy, CONNEXION_STEPS } from "~/utils/enums/auth.enums";
import { useAuthStore } from "~/store/auth/auth.store";
import { useAuthValidation } from "~/composables/auth/useAuthValidation";
import { useAuthApi } from "~/composables/auth/useAuthApi";
import { UserStatusEnum } from "~/utils/enums/users.enums";
import { useAuthSSOApi } from "~/composables/auth/useAuthSSOApi";
import { useFeedback } from "~/composables/feedback/useFeedback";

const authStore = useAuthStore();
const { connexion } = storeToRefs(authStore);
const authValidation = useAuthValidation();
const authApi = useAuthApi();
const authSSOApi = useAuthSSOApi();
const feedback = useFeedback();

const $e = await authValidation.stepLoginEmail();
const inputEmail = ref<HTMLInputElement | null>(null);
const $error: Ref<string | null | undefined> = ref();
const config = useRuntimeConfig();

onMounted(() => {
  if (inputEmail.value) {
    inputEmail.value?.focus();
  }
});

watch($e, () => {
  $error.value = null;
  if ($e.value.$error) {
    $error.value = $e.value.$errors.find(Boolean)?.$message as string;
  }
});

async function clickLoginEmail() {
  await $e.value.$validate();

  if ($e.value.$error) {
    return;
  }

  if (connexion.value.login_email) {
    const { error, data } = await authApi.logAttempt(connexion.value.login_email);

    if (!data.value) {
      $error.value = "Cet email ne correspond à aucun compte.\nUn problème ? Contactez votre administrateur RH.";
      return;
    }


    if (data.value.auth_strategy === AuthStrategy.EMAIL_PASSWORD) {
      if (data.value.status === UserStatusEnum.REGISTERED)
        connexion.value.connexion_step = CONNEXION_STEPS.LOGIN_PASSWORD;
      else if (data.value.status === UserStatusEnum.UNREGISTERED) {
        await sendFirstPassword();
      }
    } else if (data.value.auth_strategy === AuthStrategy.SSO_SAML) {
      const SSOPayload = (await authSSOApi.getCompanySSOProvider(data.value.company_id))?.data?.value;
      if (!SSOPayload) {
        $error.value =
          "Impossible de récupérer les informations SSO.\nUn problème ? Contactez votre administrateur RH.";
        return;
      }

      return navigateTo(`${config.public.AUTH_URL}/auth/teams/sso/login/${SSOPayload.external_id}`, { external: true });
    }
  }
}

async function sendFirstPassword() {
  if (connexion.value.login_email) {
    const { data, error } = await authApi.generateResetPassword(connexion.value.login_email);

    if (!data.value || error?.value) {
      feedback.error(`Une erreur est survenue`, "small");
      return;
    }

    connexion.value.show_first_password_sent = true;
  }
}
</script>

<template>
  <form class="connexion__form" @submit.prevent="clickLoginEmail">
    <div class="connexion__form_input_container">
      <label for="connexion_email" class="body_med text-secondary-color">Email professionnel *</label>
      <input id="connexion_email" ref="inputEmail" v-model="connexion.login_email" :data-error="!!$error" type="text"
        class="input__default body_med" placeholder="jules.ferry@entreprise.com" />
      <p v-if="$error" class="text-primary-red-color body_med">
        {{ $error }}
      </p>
    </div>

    <div class="connexion__form_button_container">
      <button class="button__default">Continuer</button>
    </div>
  </form>
</template>

<style lang="scss" scoped></style>
